<template>
  <b-card>
    <validation-observer ref="mainAttributeEditRules" tag="form">
      <b-form>
        <b-row class="mb-1 match-height">
          <b-col cols="12" md="6">
            <b-card class="border rounded">
              <b-card-title class="text-center">
                {{ $t('attribute.sidebar.title') }}
              </b-card-title>
              <section class="mb-2">
                <app-form-text-input-itn
                  v-model="attribute.name"
                  :label="$t('attribute.name')"
                  :languages="activelang"
                  form-name="attribute-add"
                />

                <enum-select id="type" v-model="attribute.type" :clearable="false" :label="$t('attribute.type')" enum-class="AttributeType" required />

                <b-form-checkbox v-model="attribute.filterable" :checked="attribute.filterable" class="d-inline" name="check-button" switch>
                  {{ $t('attribute.filterable') }}
                </b-form-checkbox>

                <b-form-checkbox v-model="attribute.required" :checked="attribute.required" class="d-inline ml-2" name="check-button" switch>
                  {{ $t('attribute.required') }}
                </b-form-checkbox>
              </section>
              <hr class="mt-3" />
              <section v-if="!attributeLoading">
                <b-card class="mb-0">
                  <div class="d-flex align-items-center mb-50">
                    <b-form-group :label="$t('attribute.attached_categories')" class="ml-50 mb-0 validation-required" label-for="categories_attached" />
                    <div class="ml-auto">
                      <b-button size="sm" variant="outline-primary" @click="checkAll = !checkAll">
                        <font-awesome-icon :icon="checkAll ? 'minus' : 'plus'" class="mr-50" />
                        {{ checkAll ? $t('action.uncheck_all') : $t('action.check_all') }}
                      </b-button>
                    </div>
                  </div>
                  <div class="border-primary rounded cat-list">
                    <categories-tree
                      :check-all.sync="checkAll"
                      :current-parent="attribute.categories"
                      multiselect
                      @category-selected="onSetCategorySelected"
                    />
                  </div>
                </b-card>
              </section>
            </b-card>
          </b-col>
          <!--VALUES-->
          <b-col md="6">
            <b-card class="border rounded wrapper_attribute-value">
              <b-card-title class="text-center">
                {{ $t('attribute.values') }}
              </b-card-title>
              <AttributeEditValues
                v-model="attribute.values"
                :attribute-loading="attributeLoading"
                @onAttributeValueIconUploaded="saveAvailable = $event"
                @onValues="onValues"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row class="justify-content-end mr-0 p-1 end-line">
      <b-button :to="{ name: 'attributes' }" class="mr-50" type="button" variant="outline-secondary">
        <font-awesome-icon class="mr-50" icon="ban" />
        {{ $t('action.cancel') }}
      </b-button>
      <b-button class="d-flex" @click="saveAvailable ? CheckFormEdit() : toaster($t('alert.wait_files_processing'))">
        <b-spinner v-if="!saveAvailable" class="mr-50" small />
        <font-awesome-icon v-else class="mr-50" icon="save" />
        {{ $t('action.save_and_quit') }}
      </b-button>
    </b-row>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'

import CategoriesTree from '@/views/category/CategoriesTree.vue'
import AttributeEditValues from '@/views/attribute/AttributeEditValues.vue'
import { fetchAttributeRequest, patchAttributeRequest } from '../../request/globalApi/requests/attributeRequests'

export default {
  name: 'AttributeEdit',

  components: {
    AttributeEditValues,
    CategoriesTree,
  },
  data() {
    return {
      attributeLoading: false,
      saveAvailable: true,
      checkAll: false,
      attribute: {
        name: {},
        filterable: false,
        required: false,
        type: 'input',
        categories: [],
        values: [],
      },
      translations: null,
    }
  },

  computed: {
    ...mapState('appConfig', ['lang']),
    ...mapState('auth', ['activelang']),
  },

  mounted() {
    this.attributeLoading = true
    fetchAttributeRequest(this.$route.params.attribute_id)
      .then(response => {
        const { attribute } = response.data
        attribute.categories = attribute.categories.map(cat => cat.id)
        this.attribute = attribute
      })
      .finally(() => {
        this.attributeLoading = false
      })
  },
  methods: {
    onValues(val) {
      this.attribute.values = val
    },
    CheckFormEdit() {
      return this.$refs.mainAttributeEditRules.validate().then(success => {
        if (success) {
          if (!this.attribute.categories.length) {
            this.toaster(this.$t('alert.select_at_least_one_category'), 'danger', 'EditIcon')
          } else {
            patchAttributeRequest(this.attribute, this.attribute.name[this.lang]).then(() => {
              this.$router.push({ name: 'attributes' })
            })
          }
        } else {
          this.toaster(this.$t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
        }
      })
    },
    onSetCategorySelected(cats) {
      this.attribute.categories = []
      cats.forEach(cat => {
        this.attribute.categories.push(cat.id)
      })
    },
  },
}
</script>

<style scoped>
.cat-list {
  max-height: 255px;
  overflow: auto;
}
</style>
